import request from '@/utils/request'


// 查询用户注销详细
export function getEmailChannel(code) {
  return request({
    url: '/email/email-channel/detail?code='+code,
    method: 'get'
  })
}



// 修改用户注销
export function updateEmailChanne(data) {
  return request({
    url: '/email/email-channel/edit',
    method: 'post',
    data: data
  })
}

